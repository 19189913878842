<template>
  <div />
</template>

<script>
export default {
  name: "CheckTokenResetPassword",
  metaInfo() {
    return {
      title: "登録メールを確認する",
      titleTemplate: "%s | PreGo"
    };
  },
  async created() {
    this.$root.$refs.loading.start();
    await this.$store
      .dispatch("resetPassword/checkToken", { token: this.$route.query.token })
      .then(() => {
        localStorage.setItem("token_reset", this.$route.query.token);
        this.$router.push({
          name: "ResetPassword",
          params: { token: this.$route.query.token }
        });
        this.$root.$refs.loading.finish();
      })
      .catch(error => {
        if (error.response.data.error.code === "5022") {
          this.$toast(
            "認証コードの有効期限が過ぎています。",
            "不合格",
            "danger"
          );
          this.$router.push({ name: "ResetPasswordByEmail" });
          this.$root.$refs.loading.finish();
        }
      });
  }
};
</script>
